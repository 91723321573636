<template>
  <div class="auth">
    <div>
      <div class="auth__container">
        <div class="title">Восстановление пароля</div>
        <div class="info">Введите новый пароль</div>
        <input type="text" placeholder="Введите новый пароль" v-model="reset.password" />
        <input type="text" placeholder="Повторите новый пароль" v-model="reset.replace_password" />
        <button class="btn btn-auth btn-gradient" @click="changePassword">Сменить пароль</button> 
      </div>
    </div>
  </div>
</template>
<script>
import Axios from 'axios';

export default {
  name: 'ResetMain',
  data() {
    return {
      reset: {
        password: '',
        replace_password: '',
        token: this.getQueryParam('token'),
      }
    }
  },
  mounted() {
    this.$store.dispatch("checkToken");
  },
  methods: {
    changePassword() {
      Axios
        .post(`/api/auth/reset`, this.reset)
        .then(res => {
          this.$router.push('/admin/auth');
        })
        .catch(err => {
          this.$bus.$emit('showNotification', {message: err.response.data, status: 'danger'});
          console.error(err.response);
        });
    },
    getQueryParam (param) {
      let queries = window.location.search, regex, resRegex, results, response;
      param = encodeURIComponent(param);
      regex = new RegExp('[\\?&]' + param + '=([^&#]*)', 'g');
      resRegex = new RegExp('(.*)=([^&#]*)');
      results = queries.match(regex);

      if (!results) return '';
      response = results.map(function (result) {
        let parsed = resRegex.exec(result);
        if (!parsed) return '';
        return parsed[2].match(/(^\d+$)/) ? Number(parsed[2]) : parsed[2];
      })
      return response.length > 1 ? response : response[0];
    },
  }
}
</script>

<style lang="scss" scoped>
.auth {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  color: #00325C;
  text-align: center;
  div {
    .auth__container {
      display: flex;
      flex-direction: column;
      width: 350px;
      .title {
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 15px;
      }
      .info {
        font-size: 14px;
        margin-bottom: 15px;
      }
      .reset {
        margin-top: 10px;
        font-size: 12px;
        cursor: pointer;
      }
      input {
        margin-bottom: 15px;
        &[type="submit"] {
          width: 45%;
          margin: 15px 95px;
          &.btn-reset {
            width: 58%;
            margin: 15px 73px;
          }
        }
      }
    }
  }
}
</style>